import React, { Component } from "react";
import ListItem from "./ListItem";

class DataList extends Component {
  clicked(id) {
    this.props.clicked(id);
  }

  render() {
    const data = this.props.data;
    const listItems = data.map(data => {
      return (
        <ListItem key={data.id} data={data} clicked={this.clicked.bind(this)} />
      );
    });

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID Client</th>
            <th>Proiect</th>
            {data[0].proiect_id === 373 && <th>Nume firma</th>}
            <th>Ultima accesare</th>
            <th>Ultimul status</th>
            <th>Telefon</th>
            <th>Inregistrari</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    );
  }
}

export default DataList;
