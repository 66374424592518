import React, { Component } from "react";

import axios from "axios";
import Searching from "./components/Searching";
import DisplayData from "./components/DisplayData";
import DataList from "./components/DataList";
import config from "./config";

import "./App.css";
const SCT = "#BC_vOiCe_20";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      // isLogged: true,
      customers: [],
      error: "",
      isLoading: true,
      openModal: false,
      customer: {},
      total: 0,
      isSearching: false,
      token: null,
      offset: 0,
      term: "",
    };

    this.closeModal = this.closeModal.bind(this);
    this.prepareSearching = this.prepareSearching.bind(this);
  }

  componentDidMount() {
    const token_from_url = this.getTokenFromUrl();
    if (token_from_url) {
      this.setState({
        token: token_from_url,
      });

      this.searching();
    }
  }

  getTokenFromUrl() {
    const url = window.location.href;

    const captured = /token=([^&]+)/.exec(url);

    if (captured && captured.length > 1) {
      return captured[1];
    }

    return false;
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.refs.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({
      openModal: false,
      customer: {},
    });
  }

  onClick(id) {
    let customer = this.state.customers.find((customer) => customer.id === id);

    this.setState({
      openModal: true,
      customer: customer,
    });
  }

  async prepareSearching(term) {
    await this.setState({
      offset: 0,
      term,
    });

    this.searching();
  }

  searching() {
    const { term, offset } = this.state;

    let url =
      config.url_customer +
      "customers?token=" +
      this.getTokenFromUrl() +
      "&q=" +
      term +
      "&offset=" +
      offset;

    this.setState({
      isSearching: true,
    });

    var self = this;

    axios
      .get(url)
      .then(function (response) {
        const { customers, total } = response.data;
        self.setState({
          customers,
          total,
          isSearching: false,
          isLoading: false,
        });
      })
      .catch(function (error) {
        self.setState({
          error: error.response ? error.response.data.message : "Eroare api!",
          isSearching: false,
          isLoading: false,
        });
      });
  }

  async nextPage() {
    const offset = this.state.offset + 1;

    await this.setState({
      offset,
    });

    this.searching();
  }

  async previousPage() {
    if (this.state.offset === 0) return false;

    const offset = this.state.offset - 1;
    await this.setState({
      offset,
    });

    this.searching();
  }

  displayLoading = () => {
    return (
      <div
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50px",
          marginTop: "200px",
        }}
      >
        <object data="loading.svg" type="image/svg+xml"></object>
      </div>
    );
  };

  putHerePassword = () => {
    const displayError = this.state.error ? (
      <div className="alert alert-danger">{this.state.error}</div>
    ) : null;

    return (
      <div className="row">
        <div className="col-md-3 col-md-offset-3">
          {displayError}
          <div className="form-row" style={{ margin: 10 }}>
            <div className="form-group">
              <label>Parola de acces: </label>
              <input
                type="password"
                placeholder="Parola de acces"
                className="form-control"
                style={{ marginLeft: 10 }}
                onKeyPress={(e) => {
                  const value = e.target.value;

                  if (SCT === value) {
                    this.setState({ isLogged: true, error: "" });
                  } else {
                    this.setState({ error: "Parola este gresita." });
                  }
                }}
              />
              <small className="ml-3">Folositi tasta Enter</small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (!this.state.isLogged) {
      return this.putHerePassword();
    }
    if (!this.state.token) {
      return <h1>No token provided!</h1>;
    }
    const modal =
      this.state.openModal && this.state.customer ? (
        <DisplayData
          customer={this.state.customer}
          closeModal={this.closeModal}
          isOpen={this.state.openModal}
        />
      ) : null;
    const error = this.state.error ? (
      <h2 style={{ color: "red" }}>Eroare: {this.state.error.toString()}</h2>
    ) : null;
    if (this.state.isLoading) {
      return this.displayLoading();
    }

    const title =
      "Nr total de inregistrari: " +
      this.state.total +
      " si afisata pe pagina:" +
      (this.state.offset + 1) +
      " un numar de  " +
      this.state.customers.length +
      " de inregistrari.";
    const pagina = "Pagina: " + (this.state.offset + 1);
    const next = (
      <button
        disabled={this.state.isSearching || this.state.customers.length < 100}
        className="btn btn-primary"
        onClick={() => {
          this.nextPage();
        }}
      >
        Pagina urmatoare
      </button>
    );
    const previous = (
      <button
        disabled={this.state.isSearching || this.state.offset === 0}
        className="btn btn-danger"
        onClick={() => {
          this.previousPage();
        }}
      >
        Pagina dinainte
      </button>
    );
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>{title}</h1>
            <h2>{pagina}</h2>
            {previous} {next}
            {error}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Searching
              search={this.prepareSearching}
              isLoading={this.state.isSearching}
            />
          </div>
          <div className="col-md-12">
            {this.state.isLoading === false ? (
              <DataList
                data={this.state.customers}
                clicked={this.onClick.bind(this)}
              />
            ) : null}
          </div>
        </div>

        {modal}
        <div style={{ float: "right" }}>
          {previous} {next}
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default App;
