import React, { Component } from "react";
import Modal from "react-modal";
import config from "../config";

import AudioPlayer from "react-responsive-audio-player";

class DisplayData extends Component {
  closeModal() {
    this.props.closeModal();
  }

  render() {
    const customer = this.props.customer;
    const records = customer.cdr.map((each_record) => {
      let playlist = null;
      let audioPlay = null;

      if (each_record.billsec) {
        let d = each_record.calldate.split("T")[0];
        const url =
          config.url_get_audio +
          "getAudio?s=" +
          each_record.linkedid +
          "&client_id=" +
          customer.id +
          "&src=" +
          each_record.src +
          "&calldate=" +
          d;

        playlist = [
          {
            url: url,
            title: each_record.src,
          },
        ];

        audioPlay = (
          <AudioPlayer
            playlist={playlist}
            controls={["playpause", "progress"]}
          />
        );
      }

      const durata_conversatie =
        each_record.disposition === "ANSWERED" && each_record.billsec > 0
          ? Math.floor(each_record.billsec / 60) +
            " minute si " +
            (each_record.billsec % 60) +
            " secunde"
          : " - ";
      return (
        <tr
          key={each_record.uniqueid}
          style={
            each_record.disposition === "ANSWERED"
              ? { backgroundColor: "#27ae60", color: "white" }
              : { backgroundColor: "#34495e", color: "white" }
          }
        >
          <td>{each_record.calldate}</td>
          <td>{each_record.disposition}</td>
          <td>{durata_conversatie}</td>
          <td>{each_record.src} </td>
          <td style={{ width: "450px" }}>{audioPlay}</td>
        </tr>
      );
    });

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel="CDR"
        onRequestClose={this.closeModal.bind(this)}
      >
        <h1>Detalii client ID: {customer.id}</h1>
        <h3>Inregistrari: </h3>
        <table className="table table-stripped table-bordered">
          <thead>
            <tr>
              <th>Cand</th>
              <th>Ultima dispozitie</th>
              <th>Durata conversatie</th>
              <th>Telefon</th>
              <th>Inregistrare</th>
            </tr>
          </thead>
          <tbody>{records}</tbody>
        </table>

        <button onClick={this.closeModal.bind(this)}>Inchide</button>
      </Modal>
    );
  }
}

export default DisplayData;
